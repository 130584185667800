import React, { useState } from 'react';
import api from '../../services/api';
import Header from '../../components/header';
import TitlePage from '../../components/title-page';

export default function ImportPublisher() {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [uploadStatus, setUploadStatus] = useState("");
    const [isFileImported, setIsFileImported] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Estado para el porcentaje de carga
    const [loading, setLoading] = useState(false); // Estado para el loader
    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const csvFileToArray = (string) => {
        var string2 = string.replaceAll('\r', '');

        const csvHeader = string2.slice(0, string2.indexOf("\n")).split(",");
        const csvRows = string2.slice(string2.indexOf("\n") + 1).split("\n");

        const newArray = csvRows.map(i => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });
        var newArray2 = []
        for (var i = 0; i < newArray.length; i++) {
            if (newArray[i]["Publisher Name"] !== '')
                newArray2.push(newArray[i])
        }

        setArray(newArray2);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (isFileImported) {
            try {
                setLoading(true); // Mostrar loader
                setUploadStatus("Uploading data...");
                let progress = 0; // Inicializamos el progreso

                // Agrupar registros por Publisher Name
                const groupedPublishers = array.reduce((acc, item) => {
                    const { "Publisher Name": name } = item;
                    if (!acc[name]) {
                        acc[name] = [];
                    }
                    acc[name].push(item);
                    return acc;
                }, {});

                const totalItems = Object.keys(groupedPublishers).length;

                for (const [index, [name, items]] of Object.entries(Object.entries(groupedPublishers))) {
                    const firstItem = items[0];
                    const {
                        "Email": email,
                        "Phone Number": phone,
                        "Tags": tags,
                        "Contact Name": contact_name,
                        "Contact Last Name": contact_last_name
                    } = firstItem;

                    let publisherResponse;

                    if (name === undefined || name === "")
                        continue;

                    const existingPublisher = await api.get(`publishers?filter[where][name]=${name}`);

                    if (existingPublisher == null || existingPublisher.data == null) {
                        // return error
                    }

                    if (existingPublisher.data.length === 0) {
                        publisherResponse = await api.post("publishers", {
                            name,
                            email,
                            phone,
                            contact_name,
                            contact_last_name,
                            tags
                        });
                    } else {
                        publisherResponse = { data: existingPublisher.data[0] };
                    }

                    const publisher_id = publisherResponse.data.id;

                    var i = 0;
                    if (existingPublisher.data.length === 0) i = 1;

                    for (; i < items.length; i++) {
                        const {
                            "Contact Name": e_name,
                            "Email": e_email,
                            "Phone Number": e_phone,
                            "Contact Last Name": e_last_name
                        } = items[i];

                        if (e_last_name === undefined) e_last_name = " ";

                        var postString = "publishers/" + publisher_id + "/employees";
                        await api.post(postString, {
                            name: e_name,
                            last_name: e_last_name,
                            email: e_email,
                            office_post: [],
                            phone: e_phone,
                            setup: "TO"
                        });
                    }

                    // Incrementar el progreso en función del número de ítems procesados
                    progress = Math.floor((index / totalItems) * 100);
                    setUploadProgress(progress);
                }

                setUploadProgress(100); 
                setUploadStatus("Data uploaded successfully");
                setLoading(false); 
                window.location.href = '../list-publishers/1';
            } catch (error) {
                console.error("Error uploading data:", error);
                setUploadStatus("Some Data Could Not be Loaded Correctly");
                setLoading(false); 
            }
        } else {
            if (file) {
                fileReader.onload = function (event) {
                    const text = event.target.result;
                    csvFileToArray(text);
                    setIsFileImported(true);
                    setUploadStatus("Data loaded. Click 'Import Publishers' to upload.");
                };
                fileReader.readAsText(file);
            }
        }
    };

    return (
        <div data-page="import-publisher">
            <Header />
            <TitlePage title="Import Publishers" />
            <div className="container-fluid" data-component="content-importpublisher">
                <div className="container gutter">
                    <h2>Import Publisher List</h2>
                    <br />
                    <a href='../list-publishers/1' className="btn blue-light btn-save">
                        Back to Publishers
                    </a>
                    &nbsp;
                    <a href='../publisher/0' className="btn blue-light btn-save">
                        New Publisher
                    </a>
                    <br />
                    <br />
                    <p><strong>Important!</strong> To load all data, it is necessary to upload a CSV file.</p>
                    <br />
                    <form onSubmit={handleOnSubmit}>
                        <input
                            className='btn'
                            type={"file"}
                            id={"csvFileInput"}
                            accept={".csv"}
                            onChange={handleOnChange}
                        />
                        <br />
                        <br />
                        <button type="submit" className="btn blue-light btn-save">
                            {isFileImported ? "Import Publishers" : "Load Data"}
                        </button>
                        <br />
                    </form>
                    <br />
                    {uploadStatus && <p>{uploadStatus}</p>}
                    {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>} 
                    {loading && (
                        <div class="loader-wrapper">   
                            <div class="loader-container">                     
                            </div>
                        </div>
                    )}

                    {array.length > 0 && (
                        <div className='container-table'>
                            <div className='responsive-table'>
                                <br />
                                <center>
                                    <table className='responsive-table'>
                                        <thead>
                                            <tr className='table-header col col-1'>
                                                {Object.keys(array[0]).map((key) => (
                                                    <th key={key}>{key} | &nbsp;</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {array.map((item, index) => (
                                                <tr className='table-row' key={index}>
                                                    {Object.values(item).map((val, idx) => (
                                                        <td className='col col-1' key={idx}>{val}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </center>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
